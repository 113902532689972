// 引入axios
import axios from 'axios';

export let baseUrl = "/api";//创建axios实例


axios.defaults.withCredentials = true

const httpService = axios.create({
    //baseURL: process.env.BASE_API,//需自定义
    baseURL: baseUrl,
    //请求超时时间
    timeout: 10000 //需自定义
});

// 用来存放调用此js的vue组件实例（this）
let vm = null

const sendThis = (_this) => {
    vm = _this
}


//添加请求和响应拦截器
//添加请求拦截器
httpService.interceptors.request.use(function (config) {
    // //在发送请求之前做些什么
    // //config.headers.token=window.sessionStorage.getItem( 'token ');
    // console.log( "store="+store.getters.GET_TOKEN)
    // config.headers.token=store.getters.GET_TOKEN
    return config;
}, function (error) {
    //对请求错误做些什么
    return Promise.reject(error);
});


// 添加响应拦截器
httpService.interceptors.response.use(function (response) {
    //对响应数据做点什么
    if (response.status === 200) {
        const data = response.data
        if (data.status === 500) {
            console.log(data.message)
        } else if (data.status === 401) {
            //跳转到登录页面
            if (vm != null) {
                vm.$router.replace('/index')
            }
        }
    } else {
        console.log('请求接口出现问题')
    }
    return response;
}, function (error) {
    //对响应错误做点什么
    return Promise.reject(error);
});


function setToken(url, needToken) {
  if (typeof needToken == 'undefined' || needToken) {
    if(localStorage.getItem('token')){
      url+= (url.indexOf("?") !=-1?"&":"?")+'token='+localStorage.getItem('token')
      if(localStorage.getItem('w')){
        url+= (url.indexOf("?") !=-1?"&":"?")+'w='+localStorage.getItem('w')
      }
    }else{
        url+= (url.indexOf("?") !=-1?"&":"?")+(url.indexOf("token") !=-1?"":"token=")
        if(localStorage.getItem('w')){
          url+= (url.indexOf("?") !=-1?"&":"?")+'w='+localStorage.getItem('w')
        }
    }
  }
  return url;
}

// 网络请求

/**
 * get请求
 * @param url 请求地址
 * @param params 请求参数
 * @returns {Promise<unknown>}
 */
export function get(url, params = {}, needToken = true) {
    url = setToken(url, needToken);
    return new Promise((resolve, reject) => {
        httpService({
            url: url,
            method: 'get',
            params: params
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}



/**
 * 请求
 * @param type 请求类型
 * @param url 请求地址
 * @param params 请求参数
 * @returns {Promise<unknown>}
 */
export function req(type, url, params = {}, needToken = true) {
    url = setToken(url, needToken);
    return new Promise((resolve, reject) => {
        httpService({
            url: url,
            method: type,
            data: params
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

/**
 * post请求
 * @param url 请求地址
 * @param params 请求参数
 * @returns {Promise<unknown>}
 */
export function post(url, params = {}, needToken = true) {
    url = setToken(url, needToken);
    return new Promise((resolve, reject) => {
        httpService({
            url: url,
            method: 'post',
            data: params
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}


// 验证是否为blob格式
export async function blobValidate(data) {
    try {
        const text = await data.text();
        JSON.parse(text);
        return false;
    } catch (error) {
        return true;
    }
}

export default {
    get,
    post,
    sendThis,
    req,
    baseUrl
}

<template>
  <div class="hello">
      <div class="car-card-pay">
        <div class="car-card-bg">
        </div>
        <div class="car-card">
          <div class="car-img">
            <img :src="item.placeMainImage" @error="nofind"/>
          </div>
          <div class="car-info">
            <div class="car-title">{{item.placeName}}</div>
            <div class="car-star">
              <div class="car-brand">{{item.companyName}}</div>
              <div class="car-star-bg" v-if="false">
                <van-rate size="10" v-model="rateValue" color="#F96501"/>
              </div>
            </div>
            <div class="car-call" v-for="(_item, _index) in item.placeDescribe.split('、')" :key="_item">
              <div class="car-phone"><a :href="'tel:' + _item">{{_item}}</a></div>
              <div class="car-distance" v-if="_index==0">
                {{item.distance}}km
              </div>
            </div>
            <div class="car-address">{{item.address}}</div>
          </div>
        </div>
        <div class="car-pay-panel">
          <div class="car-flag">
            ¥
          </div>
          <div style="width:80%">
            <input @click="showInput" readonly="true" type="text" ref="amountInputName" v-model="amount" placeholder="请输入消费金额" @input="amountInput"/>
          </div>
        </div>
        <van-number-keyboard
          safe-area-inset-bottom
          :show="show"
          theme="custom"
          close-button-text="去核销"
          extra-key=""
          @blur="show = false"
          @input="onInput"
          @delete="onInput"
          @close="goPay"
          v-model="amount"
        />
        <div v-if="submiting" class="car-pay car-pay-f">核销中</div>
        <div v-if="!submiting" :class="amount>0?'car-pay car-pay-success':'car-pay car-pay-f'" @click="goPay">去核销</div>
      </div>
  </div>
</template>

<script>
import { Dialog } from 'vant-green';
import { NumberKeyboard } from 'vant-green';
import { findPlaceById,createOrder } from '@/api/apis.js'
export default {
  name: 'Detail',
  data() {
    return {
      submiting:false,
      ccbParamSJ:this.$route.query.ccbParamSJ || '',
      show:true,
      lng:this.$route.query.lng || 116.46542060395913,
      lat:this.$route.query.lat || 39.97456040536952,
      item:{placeDescribe:''},
      id: this.$route.query.id || '',
      amount:'',
      keyword:'',
      filterId:'',
      value: '',
      showAction: false,
      list: [],
      loading: false,
      finished: false,
      rateValue: 5,
      filterList:[],
      carList: [
      ],
      isIOS: false,
      isAndroid: false
    };
  },
  mounted () {
    this.checkOS();
    if (typeof this.GLOBAL.item != 'undefined' && this.GLOBAL.item != null) {
      this.item = this.GLOBAL.item;
      if (typeof this.item.distance.toFixed != 'undefined') {
        this.item.distance = this.item.distance.toFixed(2);
      } else {
        this.item.distance = this.item.distance;
      }
      document.title = this.item.placeName;
    } else {
      this.loadShopData();
    }
  },
  methods: {
    nofind(e) {
      e.target.src = 'https://img.tglmall.com.cn/wd/000.png';
    },
    checkOS() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      this.isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
      this.isAndroid = /android/i.test(userAgent);
    },
    showInput() {
      this.show = true;
    },
    getDistance: function (lat1, lng1, lat2, lng2) {
      var that = this;
      lat1 = lat1 || 0;
      lng1 = lng1 || 0;
      lat2 = lat2 || 0;
      lng2 = lng2 || 0;
      var rad1 = lat1 * Math.PI / 180.0;
      var rad2 = lat2 * Math.PI / 180.0;
      var a = rad1 - rad2;
      var b = lng1 * Math.PI / 180.0 - lng2 * Math.PI / 180.0;
      var r = 6378137;
      var distance = r * 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(rad1) * Math.cos(rad2) * Math.pow(Math.sin(b / 2), 2)));
      return distance;
    },
    loadShopData() {
      let that = this;
      findPlaceById(this.id).then(res => {
        if (res.data.code == 200) {
          this.item = res.data.data;
          let hw = this.getDistance(this.lat, this.lng, this.item.latitude, this.item.longitude);
          this.item.distanceNum = hw;
          this.item.distance = hw +"米";
          if (hw && hw !== -1 && hw > 1000) { //拿到正确的值
              //转换成公里
              hw = (hw / 2 / 500).toFixed(2) + 'km'
              this.item.distance = hw;
          } else {
              this.item.distance = "100米内";
          }
          document.title = this.item.placeName;
        }
      }).catch(err => { console.log('获取类别失败', err); })
    },
    // 检查客户端
    getClient() {
      let client = '';
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
        client = 'iOS';
      } else if (/(Android)/i.test(navigator.userAgent)) {  //判断Android
        client = 'Android';
      } else {
        client = 'PC';
      }
      return client;
    },
    goPay() {
      let that = this;
      that.submiting = true;
      if (this.amount == '' || Number(this.amount) <= 0) {
        that.submiting = false;
        Dialog({ message: '请您输入正确的消费金额' });
        return false;
      }

      createOrder(this.id, this.amount).then(res => {
        if (res.data.code == 200) {
          that.amount = '';
          that.submiting = false;
          if (that.isIOS) {
            that.MBS_DIRECT_PAY_IOS(res.data.params);
          } else {
            that.MBS_DIRECT_PAY_ANDROID(res.data.params);
          }
        }
      }).catch(err => { console.log('获取类别失败', err); that.submiting = false;})
    },
    //IPHONE调起收银台
    MBS_DIRECT_PAY_IOS(payInfo){
        window.location="mbspay://direct?"+ payInfo;
    },
    //安卓调起收银台
    MBS_DIRECT_PAY_ANDROID(payInfo){
        window.mbspay.directpay(payInfo);
    },
    onInput() {

    },
    amountInput(e) {
      console.log(e.target.value);
      if (e.target.value <= 0) {
        this.amount = '';
        return '';
      } else {
        let _amount = e.target.value;
        if (isNaN(Number(_amount))) {
          this.amount = '';
          return false;
        }

        if (_amount.indexOf('.') != -1) {
          if (_amount.split(".")[1].length > 2) {
            _amount = Math.floor(Number(e.target.value) * 100) / 100;
            _amount = _amount.toFixed(2);
          }
        }
        this.amount = _amount;
      }
    },
    // 返回上一页
    onClickLeft() {
      this.$router.go(-1);
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.car-flag {
  width: 30px;
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 16px;
  color: #25282A;
  line-height: 22px;
  text-align: center;
}
.car-pay-panel {
  width: 90%;
  margin: 20px auto;
  height: 44px;
  background: #FFFFFF;
  border-radius: 8px;
  border: 2px solid #F96501;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.car-pay-panel input {
  width: 95%;
  height: 39px;
  border: none;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
}
.car-card-pay {
  height: 93vh;
  background: linear-gradient(#FEFCFB 0%, #F9E9E6 100%);
  width: 100%;
  padding: 0px 0px 20px 0px;
}
.bg-img {
  height: 307px;
  width: 100%;
}
.hello {
  background: #F6F7F8;
  position: relative;
}
.van-rate {
  margin-top:2px;
  margin-right:5px;
}
.car-new-panel {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.car-new-search {
  width: 355px;
  height: 126px;
}
.car-new-search img {
  width: 355px;
  height: 126px;
}
.car-card {
  margin: -90px auto auto auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  width: 95%;
  min-height: 90px;
  background: #FFFFFF;
  border-radius: 16px;
}
.car-img {
  width: 84px;
  height: 84px;
  background: #D8D8D8;
  border-radius: 8px;
}
.car-img img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.car-info {
  text-align: left;
  font-size: 12px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 10px 0px 10px;
}
.car-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 13px;
  color: #25282A;
  line-height: 22px;
  text-align: left;
  font-style: normal;
}
.car-call {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin:3px 0px;
}
.car-phone {
  background: url(https://img.tglmall.com.cn/upload_pic_ccb-phone.png-6142) no-repeat;
  height: 17px;
  width: 111px;
  line-height:17px;
  background-size: 111px 17px;
  display: flex;
  justify-content: flex-end;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #607088;
  padding-right:3px;
}
.car-phone a {
  font-size: 12px;
  color: #607088;
  text-decoration: none; /* 去掉下划线 */
}
.car-star {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin:0px 0px 3px 0px;
}
.car-star-bg {
  background: url(https://img.tglmall.com.cn/upload_pic_ccb-review.png-6143) no-repeat;
  height: 17px;
  width: 111px;
  background-size: 111px 17px;
  display: flex;
  justify-content: flex-end;
}
.car-location {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.car-address {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: rgba(37,40,42,0.6);
  line-height: 17px;
  text-align: left;
  background: url(https://img.tglmall.com.cn/upload_pic_ccb-location.png-6142) left 3px no-repeat;
  background-size: 10px 10px;
  padding-left:15px;
  margin:3px 0px 3px 0px;
}
.car-distance {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 12px;
  color: #25282A;
  text-align: center;
  font-style: normal;
  background: url(https://img.tglmall.com.cn/upload_pic_ccb-distance.png-6139) left center no-repeat;
  background-size: 8px 8px;
  padding-left:12px;
}
.car-pay {
  width: 90%;
  height: 44px;
  background: linear-gradient( 270deg, #FBA149 0%, #FE6402 100%);
  border-radius: 16px;
  margin:0px auto;
  line-height:44px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #FFFFFF;
}
.car-pay-f {
  opacity: 0.4;
}
.car-pay-success {
  opacity: 1;
}
.car-find {
  margin: 15px;
  border: 2px solid #F96501;
  border-radius: 5px;
  height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px 0px 10px;
  position: absolute;
  width: 325px;
  bottom: 0px;
}
.car-find input {
  width: 80%;
  height: 80%;
  border: none;
  outline: none;
  font-size: 12px;
}
.car-search {
  width: 80px;
  height: 28px;
  background: linear-gradient( 270deg, #FBA149 0%, #FE6402 100%);
  border-radius: 6px;
  font-size: 12px;
  color: #FFFFFF;
  line-height:28px;
}
.autoComplete {
  width: calc(100% - 30px);
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 0px 15px;
  border-radius: 5px;
  text-align: left;
  position: absolute;
  z-index: 999;
  background: #fff;
}
.autoComplete-item {
  margin: 0px 5px;
  height: 30px;
  line-height: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.autoComplete-item:last-child {
    border-bottom: none;
}
.car-brand {
  color: rgba(37, 40, 42, 0.6);
}

.car-card-bg {
  background: linear-gradient(270deg, #FBA149 0%, #FE6402 100%);
  height: 110px;
  with:100%;
  margin: 0px;
  padding: 0px;
}

::v-deep .van-number-keyboard .van-key--extra {
  display:none;
}
::v-deep .van-number-keyboard .van-key--middle {
  width:100%;
}

</style>

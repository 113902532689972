import request from "@/js/request";

let PFID = 1003;

export function getCarList(pageNumber, pageSize, lng, lat, placeId, keyword, typeId) {
  let url = '/weidaoyun/carinfo/findPlaceList.jhtml';
  return request.post(url, {pageNumber: pageNumber, pageSize: pageSize, longitude: lng,latitude: lat, placeId: placeId, keyword: keyword, typeId:typeId})
}

export function getCarNameList(typeId) {
  let url = '/weidaoyun/carinfo/findPlaceNameList.jhtml';
  return request.post(url, {typeId:typeId})
}

export function findPlaceById(placeId) {
  let url = '/weidaoyun/carinfo/findPlaceById.jhtml';
  return request.post(url, {id: placeId})
}

export function login(data) {
  let url = '/weidaoyun/carinfo/login.jhtml';
  return request.post(url, {data: data})
}

export function payDetail(data) {
  let url = '/weidaoyun/carinfo/payDetail.jhtml';
  return request.post(url, {data: data})
}

export function createOrder(id, amount) {
  let url = '/weidaoyun/shop/order/createOfflineOrder.jhtml?amount=' + amount;
  return request.post(url, {bid:id})
}

export function getOrder(sn) {
  let url = '/weidaoyun/carinfo/getOfflineOrder.jhtml?sn=' + sn;
  return request.post(url, {})
}

export function getConfig(typeId) {
  let url = '/wd_' + typeId + '.json';
  return request.post(url, {})
}
